<template>
  <div class="page-wrap">
    <Helper></Helper>
    <div class="section-wrap section-search">
      <InnerSearch @search="handleSearch" />
      <div class="wrapper-left">
        <breadcrumbs></breadcrumbs>
        <div class="wrapper-content">
          <div class="wrapper-list">
            <ItemProjectFund v-for="(item, index) in pageData.list"
              :key="item.id"
              :data="item"></ItemProjectFund>
           
          </div>
          <div class="wrapper-right">
            <QuickPublishProject path="fund" buttonLabel="发布资金项目"></QuickPublishProject>
            <BlockSuggestProject class="box-suggest"></BlockSuggestProject>
          </div>
        </div>
      </div>

      <div class="wrapper-page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageData.total"
          :page-size="10"
          @current-change="handlePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, defineAsyncComponent,computed } from 'vue'
import qs from 'qs'
import { useRoute } from 'vue-router'
import { Options, Vue } from 'vue-class-component'
import InnerSearch from '@/components/InnerSearch.vue'
import ItemProjectFund from '@/components/ItemProjectFund.vue'
import QuickPublishProject from '@/components/QuickPublishProject.vue'
import BlockSuggestProject from '@/components/BlockSuggestProject.vue'
import Helper from '@/components/Helper.vue'

import { Api } from '@/services/http'
const route = useRoute()
const loading = ref(false)
const pageData = reactive({
  total: 0,
  list: [],
})
const pagination = reactive({
  current: 1,
  size: 10,
  searchStr: '',
  city: '',
  industry: undefined,
})
if (route.query.keyword) {
  pagination.searchStr = route.query.keyword
}
const mounted = async () => {
  console.log('Api', Api)
  loadPage()
}
const handleSearch = (key, area, industry) => {
  pagination.searchStr = key
  if (area) {
    pagination.city = area
  } else {
    pagination.city = undefined
  }
  if (industry) {
    pagination.industry = industry
  } else {
    pagination.industry = undefined
  }
  loadPage()
}
const handlePage = page => {
  loadPage(page)
}
const loadPage = async (page = 1) => {
  loading.value = true
  pagination.current = page
  let data = await Api.post(
    '/client-api/funder/list',
    qs.stringify(pagination)
  )
  console.log('list', data)
  if (data) {
    let list = (data.list || []).filter(item => item.auditStatus == 2)
    pageData.list = [...list]
    pageData.total = data.total
  }
  loading.value = false
}
mounted()
</script>
<style lang="scss" scoped>
.section-search {
  padding-bottom: 30px;
  
  .wrapper-left{
    padding-top: 30px;
  }
  .wrapper-content {
    display: flex;
    
    .wrapper-right {
      flex-basis: 260px;
      margin-left: 30px;
    }
    .box-suggest {
      margin-top: 20px;
    }
  }
  .wrapper-list {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width:100%;
    .item {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  }
  .wrapper-page {
    padding: 20px;
  }
}
</style>
